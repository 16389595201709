import { postAddUserBank } from "@/api";
export default {
  data() {
    return {
      bank_name: "",
      account: "",
      account_name: ""
    };
  },
  methods: {
    binkUserBank() {
      if (!this.bank_name) {
        this.$toast("请输入银卡名称");
        return;
      }
      if (!this.account) {
        this.$toast("请输入银卡卡号");
        return;
      }
      if (!this.account) {
        this.$toast("请输入账户名");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postAddUserBank({
        bank_name: this.bank_name,
        account: this.account,
        account_name: this.account_name
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$router.go(-1);
      });
    }
  }
};